import { Setting } from '@src/__generated__/types';
import React from 'react';

export function getSettingContextDefaultValue(): Setting {
    return {
        enableSearch: false,
        showPublish: false,
        enableContentTranslation: false,
        cgtSettings: {
            coolsalesExporter: {
                couch: {
                    dbUri: '',
                    dbName: '',
                },
            },
            glsExporter: {
                couch: {
                    dbUri: '',
                    dbName: '',
                },
                sql: {
                    host: '',
                    user: '',
                    password: '',
                    database: '',
                },
            },
            listiniPerZMABCode: '',
            itemManodoperaCode: '',
            manodoperaCode: '',
            skipCertificate: true,
            skipPublish: true,
            skipExport: true,
            skipStatus: true,
            skipHinto: true,
            showNodeStatuses: false,
            longListsHierarchyObjectSchemaId: '',
            shortListFamilyHierarchyObjectSchemaId: '',
            sapProductHierarchyObjectSchemaId: '',
            glHierarchyObjectSchemaId: '',
            prototypeSetting: {
                modelCode: '',
                objectSchemaCode: '',
                glCodes: [],
                certificationStatuses: {
                    certified: '',
                    draft: '',
                    certifiedDraft: '',
                    error: '',
                    inCertification: '',
                    certifiedPublished: '',
                },
                priceCodes: {
                    dn: {
                        key: '',
                    },
                    dn_cash_d: {
                        key: '',
                    },
                    tpa: {
                        key: '',
                    },
                    gp: {
                        key: '',
                    },
                    list_price: {
                        key: '',
                    },
                    lc: {
                        key: '',
                    },
                    gl_list_price: {
                        key: '',
                    },
                    gl_tpa: {
                        key: '',
                    },
                    dealer_gp: {
                        key: '',
                    },
                    dealer_ms: {
                        key: '',
                    },
                    dealer_price: {
                        key: '',
                    },
                    forced_gp: {
                        key: '',
                    },
                    forced_vn: {
                        key: '',
                    },
                    forced_dealer_gp: {
                        key: '',
                    },
                    forced_dealer_price: {
                        key: '',
                    },
                    forced_dealer_discount: {
                        key: '',
                    },
                    forced_tpa: {
                        key: '',
                    },
                    gp_ms: {
                        key: '',
                    },
                    gp_tpa: {
                        key: '',
                    },
                    markup: {
                        key: '',
                    },
                    mo: {
                        key: '',
                    },
                    ms: {
                        key: '',
                    },
                    standard_price_delta: {
                        key: '',
                    },
                    transport_costs: {
                        key: '',
                    },
                },
                prototypeHierarchyId: '',
            },
            hierarchies: {
                cGTModelHierarchyId: '',
                glHierarchyId: '',
                priceListHierarchyId: '',
                supplierHierarchyId: '',
                cGTModelUsatoCompetitorHierarchyId: '',
                glGenericHierarchyId: '',
                glUsatoCompetitorHierarchyId: '',
                glExFlottaHierarchyId: '',
                sapProductHierarchyId: '',
                sapProductCompetitorHierarchyId: '',
                marcheHierarchyId: '',
                longListsHierarchyId: '',
                commercialFamilyHierarchyId: '',
                shortListFamilyHierarchyId: '',
                sezioniSchedeHierarchyId: '',
                linkedGlsCompatibilityRulesHierarchyId: '',
                characteristicsCompatibilityRulesHierarchyId: '',
                usagesCompatibilityRulesHierarchyId: '',
                prototypeCompatibilitiesHierarchyId: '',
            },
            fields: {
                nodeTypeField: {
                    contentTypeCode: '',
                    defaultValue: '',
                },
                orderTypeField: {
                    contentTypeCode: '',
                },
                lineaSapField: {
                    contentTypeCode: '',
                },
                marcaField: {
                    contentTypeCode: '',
                },
                sapProductHierarchyField: {
                    contentTypeCode: '',
                },
                biasedField: {
                    contentTypeCode: '',
                },
                extraValueSupportField: {
                    contentTypeCode: '',
                },
                incentiveVolumeField: {
                    contentTypeCode: '',
                },
                supplierField: {
                    contentTypeCode: '',
                },
                descriptionField: {
                    contentTypeCode: '',
                },

                suffixField: {
                    contentTypeCode: '',
                },
                supplierNameField: {
                    contentTypeCode: '',
                },
                priceListField: {
                    contentTypeCode: '',
                },
                typeField: {
                    contentTypeCode: '',
                    defaultValue: '',
                },
                itemsField: {
                    contentTypeCode: '',
                },
                c1Field: {
                    contentTypeCode: '',
                },
                c2Field: {
                    contentTypeCode: '',
                },
                c3Field: {
                    contentTypeCode: '',
                },
                gpRequestedField: {
                    contentTypeCode: '',
                },
                lcField: {
                    contentTypeCode: '',
                },
                baseProgramField: {
                    contentTypeCode: '',
                },
                svField: {
                    contentTypeCode: '',
                },
                cvaField: {
                    contentTypeCode: '',
                },
                conquestField: {
                    contentTypeCode: '',
                },
                dealerDiscountField: {
                    contentTypeCode: '',
                },
                certificationStatusField: {
                    contentTypeCode: '',
                },
                installedDealerNetField: {
                    contentTypeCode: '',
                },
                notInstalledDealerNetField: {
                    contentTypeCode: '',
                },
                cashDiscountField: {
                    contentTypeCode: '',
                },
                glBaseMachineField: {
                    contentTypeCode: '',
                },
                denominationField: {
                    contentTypeCode: '',
                },
                familyField: {
                    contentTypeCode: '',
                },
                modelCodeField: {
                    contentTypeCode: '',
                },
                selectionsField: {
                    contentTypeCode: '',
                },
                flagUseDN: {
                    contentTypeCode: '',
                },
                segmento: {
                    contentTypeCode: '',
                },
                marca: {
                    contentTypeCode: '',
                },
                tipo: {
                    contentTypeCode: '',
                },
                divisione: {
                    contentTypeCode: '',
                },
            },
            groups: {
                pricingGroup: {
                    contentTypeGroupCode: '',
                },
                catSupportGroup: {
                    contentTypeGroupCode: '',
                },
                cgtSupportGroup: {
                    contentTypeGroupCode: '',
                },
                sapCharacteristicsGroup: {
                    contentTypeGroupCode: '',
                },
            },
            paths: {
                accessories: '',
                baseMachine: '',
                priceListValue: '',
                primaryPriceListValue: '',
                secondaryPriceListValue: '',
                itemsValue: '',
                sapProductHierarchyValue: '',
            },
        },
        jobList: [],
    };
}

export const SettingsContext = React.createContext<Setting>(getSettingContextDefaultValue());
