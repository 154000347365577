import {
    CheckIcon,
    Divider,
    KeyboardArrowRightIcon,
    Stack,
    Tooltip,
    Typography,
    WarningIcon,
} from '@ds/coolshop';
import { Breadcrumbs } from '@mui/material';
import { PimSideBarElement, PimSideBarElementActions } from '@src/components/layout/PimSideBar';
import { useAuth } from '@src/hooks';
import React from 'react';
import { useIntl } from 'react-intl';

import { SettingsContext } from '../setting/SettingsContext';
import { ContentTypeGroupStatuses } from './ContentTypeGroupStatuses';
import useStyles from './styles';
import { EdgeSelectedFn, NodeEdge, NodeViewMode } from './types';

type SingleNodeViewProps = {
    edge: NodeEdge;
    onEdgeSelected: EdgeSelectedFn;
    selectedNodeId: string | undefined;
    showCtgStatus: boolean;
    mode: NodeViewMode;
    isInTranslation?: boolean;
    showChildren?: boolean;
    showBreadcrumb?: boolean;
};

export const SingleNodeView = React.forwardRef<HTMLDivElement, SingleNodeViewProps>(
    (props, ref) => {
        const {
            edge,
            onEdgeSelected,
            mode,
            selectedNodeId,
            showCtgStatus,
            isInTranslation = false,
            showChildren = true,
            showBreadcrumb = false,
        } = props;

        const intl = useIntl();
        const { hasUserRole } = useAuth();
        const { nodeListEdgeBackground } = useStyles();

        const { showPublish } = React.useContext(SettingsContext);

        const isHierarchyViewMode = mode === 'hierarchy-viewer';

        const { node } = edge;

        const userHasPermissions =
            mode === 'change-parent'
                ? !!edge.node.writeRoles?.some(role => hasUserRole(role))
                : true;

        const showContentTypeGroupsStatusesIcon =
            isHierarchyViewMode && !showCtgStatus && showPublish && !isInTranslation;

        const showCheckIcon =
            (mode === 'change-parent' || mode === 'long-list-family-selection') &&
            selectedNodeId === node.id &&
            !isInTranslation;

        return (
            <>
                <PimSideBarElement
                    ref={ref}
                    stackProps={{
                        cursor: userHasPermissions ? 'pointer' : 'default',
                        bgcolor: nodeListEdgeBackground(
                            userHasPermissions,
                            selectedNodeId,
                            node.id,
                        ),
                        // eslint-disable-next-line no-nested-ternary
                        color: node.disabled
                            ? selectedNodeId === node.id
                                ? 'grey.600'
                                : 'grey.500'
                            : 'inherit',
                    }}
                    onClick={() => {
                        if (userHasPermissions) {
                            onEdgeSelected(edge, false);
                        }
                    }}
                >
                    <Stack width="100%">
                        {showBreadcrumb && (
                            <Breadcrumbs
                                separator={<KeyboardArrowRightIcon fontSize="small" />}
                                aria-label="breadcrumb"
                            >
                                {edge.node.breadcrumb
                                    ?.slice(0, edge.node.breadcrumb.length - 1)
                                    ?.map(b => (
                                        <Typography key={b.id} variant="caption">
                                            {b.listName}
                                        </Typography>
                                    ))}
                            </Breadcrumbs>
                        )}
                        <Stack width="100%" direction="row" justifyContent="space-between">
                            <Stack direction="row" alignItems="center" gap={0.5}>
                                {node.firstNodeStatus === 'notSynched' && (
                                    <Tooltip
                                        title={intl.formatMessage({
                                            id: 'hierarchy-notSynched.alert',
                                            defaultMessage: 'Non synched',
                                        })}
                                        placement="top"
                                    >
                                        <WarningIcon fontSize="small" color="warning" />
                                    </Tooltip>
                                )}

                                <Typography variant="body2">{node.listName}</Typography>
                            </Stack>

                            <PimSideBarElementActions
                                actions={[
                                    {
                                        type: 'custom',
                                        show: showContentTypeGroupsStatusesIcon,
                                        element: (
                                            <ContentTypeGroupStatuses
                                                status={node.contentTypeGroupStatus}
                                            />
                                        ),
                                    },
                                    {
                                        type: 'edit',
                                        show: isHierarchyViewMode && !isInTranslation,
                                    },
                                    {
                                        type: 'custom',
                                        show: showCheckIcon,
                                        element: <CheckIcon fontSize="small" />,
                                    },
                                    {
                                        type: 'children',
                                        show: showChildren,
                                        onClick: () => onEdgeSelected(edge, true),
                                    },
                                ]}
                            />
                        </Stack>
                    </Stack>
                </PimSideBarElement>
                <Divider mx={3} />
            </>
        );
    },
);
