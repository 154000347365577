import { useLanguageCtx } from '@modules/language';
import { Box, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { IsoCode } from '@src/__generated__/types';
import InputLabelC from '@src/components/shared/InputLabelC';
import { detonate, DETONATE_ERRORS, languageToCountry } from '@src/utils';
import { Control } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import Flag from 'react-world-flags';

import TextFieldElement from './TextFieldElement';

interface LanguageFieldProps {
    control: Control<any>;
    name: string;
    withIndex?: boolean;
    extension?: string;
    removeNameLabel?: boolean;
    addIsoCode?: boolean;
    isMultiLanguage?: boolean;
}

const LanguageFieldElement = ({
    control,
    name,
    withIndex,
    extension = '',
    removeNameLabel,
    isMultiLanguage,
    addIsoCode = true,
}: LanguageFieldProps) => {
    const { languages, defaultLanguage } = useLanguageCtx();

    const withIndexFn = (index: number) => (withIndex ? `.${index}` : '');

    if (!languages) return detonate(DETONATE_ERRORS.LANGUAGES_NOT_FOUND);

    const translations = languages?.filter(
        (l: IsoCode) => isMultiLanguage || l.isoCode === defaultLanguage,
    );

    return (
        <Box data-cy="language-field">
            {/* Name label before the TextFields with the flags (only if displayName is true) */}
            {!removeNameLabel && (
                <InputLabelC id="multilang-field">
                    <Typography variant="subtitle2" color="text.primary">
                        <FormattedMessage defaultMessage="Name" id="multilang-field" />
                    </Typography>
                </InputLabelC>
            )}

            {translations.map((ml: IsoCode, index: number) => (
                <TextFieldElement
                    required
                    key={ml.isoCode}
                    control={control}
                    id={name}
                    name={`${name}${withIndexFn(index)}${extension}`}
                    label={ml.name}
                    setIsoCode={{
                        isoCode: ml.isoCode,
                        fieldName: `${name}${withIndexFn(index)}.languageIsoCode`,
                        addIsoCode,
                    }}
                    inputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Flag
                                    code={languageToCountry(
                                        languages.find(l => l.isoCode === ml.isoCode)
                                            ?.isoCode as IsoCode['isoCode'],
                                    )}
                                    width="25"
                                />
                            </InputAdornment>
                        ),
                    }}
                    isMultiLanguage
                    data-cy="language-field-input"
                />
            ))}
        </Box>
    );
};

export default LanguageFieldElement;
