import {
    AddIcon,
    ArrowDownwardIcon,
    ArrowUpwardIcon,
    HighlightOffOutlinedIcon,
    IconButton,
    SearchIcon,
    TextField,
    Tooltip,
    Typography,
} from '@ds/coolshop';
import { Box, lighten, ListItem, Stack } from '@mui/material';
import { useLanguageCtx } from '@src/_new/modules/language';
import { LanguageFieldElement, TextFieldElement } from '@src/components/formFields';
import { debounce } from 'lodash';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Control, useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { areEqual, FixedSizeList } from 'react-window';

type SelectOptionsProps = {
    isMultiLanguage: boolean;
    name: string;
    control: Control<any>;
};

const Item = memo(({ data, index, style }: { data: any; index: number; style: any }) => {
    const { filteredFields, fields, control, remove, name, isMultiLanguage } = data;
    const intl = useIntl();
    const item = filteredFields[index];
    const indexToUse = fields.findIndex((f: { id: string }) => f.id === item.id);
    const field = fields[indexToUse];

    if (!field) {
        return null;
    }

    return (
        <ListItem component="div" key={field.id} disablePadding style={style}>
            <Stack
                width="100%"
                sx={{
                    backgroundColor: t => lighten(t.palette.primary.light, 0.95),
                    p: 2,
                    mt: 2,
                    mx: 2,
                    borderRadius: 2,
                }}
            >
                <Stack width="50%" flexDirection="row" alignItems="center">
                    <TextFieldElement
                        control={control}
                        id={`${name}.${indexToUse}.code`}
                        name={`${name}.${indexToUse}.code`}
                        label={intl.formatMessage({
                            defaultMessage: 'Code',
                            id: 'code',
                        })}
                        sxCustom={{
                            marginRight: 1,
                        }}
                        data-cy="select-option-code"
                    />
                    <Tooltip title="Remove option">
                        <IconButton aria-label="delete" onClick={() => remove(indexToUse)}>
                            <HighlightOffOutlinedIcon id="remove-select-option" />
                        </IconButton>
                    </Tooltip>
                </Stack>
                <LanguageFieldElement
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${name}.${indexToUse}.value`}
                    control={control}
                    name={`${name}.${indexToUse}.value`}
                    extension=".value"
                    isMultiLanguage={isMultiLanguage}
                    removeNameLabel
                    addIsoCode
                    withIndex
                />
            </Stack>
        </ListItem>
    );
}, areEqual);

export const SelectOptionsComponent = ({ control, isMultiLanguage, name }: SelectOptionsProps) => {
    const intl = useIntl();
    const { languages, defaultLanguage } = useLanguageCtx();

    const { watch } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control,
        name,
    });

    const listRef = useRef<FixedSizeList | null>(null);
    const [error, setError] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const creatable = watch('creatable');

    useEffect(() => {
        setError(!creatable && !fields.length);
    }, [creatable, fields]);

    const filteredFields = useMemo(
        () =>
            fields.filter(
                f =>
                    (f as unknown as { id: string; code: string }).code
                        .toLocaleLowerCase()
                        .includes(searchValue.toLocaleLowerCase()) ||
                    (f as unknown as { id: string; value: { value: string }[] }).value.some(v =>
                        v.value.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()),
                    ),
            ),
        [fields, searchValue],
    );

    const itemData = useMemo(
        () => ({
            filteredFields,
            fields,
            control,
            remove,
            name,
            isMultiLanguage,
        }),
        [filteredFields, fields, control, remove, name, isMultiLanguage],
    );

    const handleSearch = useCallback(
        debounce(val => {
            setSearchValue(val);
        }, 300),
        [],
    );

    const itemSize = useMemo(
        () => 100 + (isMultiLanguage ? languages?.length ?? 1 : 1) * 60,
        [isMultiLanguage, languages],
    );

    return (
        <Stack marginBottom={theme => theme.spacing(1.5)} gap={2}>
            <Stack width="100%" flexDirection="row" gap={2} alignItems="center">
                <Box flexGrow={1}>
                    <TextField
                        placeholder={intl.formatMessage({
                            id: 'search',
                            defaultMessage: 'Search',
                        })}
                        onChange={e => {
                            handleSearch(e.currentTarget.value);
                        }}
                        StartIcon={SearchIcon}
                    />
                </Box>
                <Tooltip
                    title={intl.formatMessage({
                        id: 'options.scroll-to-top',
                        defaultMessage: 'Scroll to top',
                    })}
                >
                    <IconButton onClick={() => listRef.current?.scrollToItem(0)}>
                        <ArrowUpwardIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip
                    title={intl.formatMessage({
                        id: 'options.scroll-to-bottom',
                        defaultMessage: 'Scroll to bottom',
                    })}
                >
                    <IconButton
                        onClick={() => listRef.current?.scrollToItem(filteredFields.length - 1)}
                    >
                        <ArrowDownwardIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip
                    title={intl.formatMessage({
                        id: 'options.add-option',
                        defaultMessage: 'Add option',
                    })}
                >
                    <IconButton
                        variant="contained"
                        onClick={() => {
                            append({
                                code: '',
                                value: [{ languageIsoCode: defaultLanguage, value: '' }],
                            });
                            listRef.current?.scrollToItem(filteredFields.length - 1);
                        }}
                    >
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            </Stack>
            <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                    height: 500,
                    border: '1px solid ',
                    borderColor: t => lighten(t.palette.primary.light, 0.85),
                    borderRadius: '4px',
                }}
            >
                {error && (
                    <Typography variant="body2" color={t => t.palette.error.main}>
                        {intl.formatMessage({
                            id: 'missing-options',
                            defaultMessage: 'Missing options',
                        })}
                    </Typography>
                )}
                {filteredFields.length ? (
                    <FixedSizeList
                        ref={listRef}
                        width="100%"
                        height={500}
                        itemSize={itemSize}
                        itemCount={filteredFields.length}
                        itemData={itemData}
                        overscanCount={10}
                    >
                        {Item}
                    </FixedSizeList>
                ) : (
                    <>
                        {intl.formatMessage({
                            id: 'options-search.no-results',
                            defaultMessage: 'No results',
                        })}
                    </>
                )}
            </Stack>
        </Stack>
    );
};
