import { InputAdornment, InputProps, TextField } from '@mui/material';
import { TextFieldProps } from '@src/assets/types';
import React, { useContext, useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { PublishContentContext } from '../../modules/hierarchy/publish/PublishContentContext';

function TextFieldElement({
    control,
    id,
    name,
    label,
    labelId,
    sideEffect,
    type = 'text',
    inputLabelProps,
    defaultValue = '',
    measureFieldProps,
    readOnly = false,
    disabled = false,
    fullWidth = true,
    multiLine = false,
    setIsoCode,
    isEditNode,
    required = false,
    sxCustom = {},
    sxTheme,
    inputProps,
    helperText,
    onKeyDown,
    validationRule = () => true,
    onChange = () => {},
    LanguageComponent,
    ...rest
}: Readonly<TextFieldProps & { InputProps?: InputProps }>) {
    const { field } = useController({
        name,
        control,
        defaultValue,
    });

    const isPublishContent = useContext(PublishContentContext);

    const { watch, setValue, getValues } = useFormContext();

    const { unitFieldName, convertToUnit } = measureFieldProps || {};

    const { isoCode, fieldName, addIsoCode } = setIsoCode || {};

    const watchUnitField = watch(unitFieldName as string);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        field.onChange(e.target.value);
        onChange(e.target.value);
    };

    useEffect(() => {
        // required conversion function for the measure field
        if (watchUnitField && convertToUnit && unitFieldName) {
            field.onChange(convertToUnit(field.value, watchUnitField).toString());
        }
    }, [watchUnitField]);

    useEffect(() => {
        if (isoCode && fieldName && addIsoCode) {
            if (!getValues(fieldName)) {
                setValue(fieldName, isoCode);
            }
        }
    }, [isoCode]);

    const IProps = LanguageComponent
        ? {
              ...inputProps,
              endAdornment: <InputAdornment position="end">{LanguageComponent}</InputAdornment>,
          }
        : rest.InputProps;

    return (
        <TextField
            error={!validationRule(field.value)}
            helperText={!validationRule(field.value) ? helperText ?? 'invalid entry' : ''}
            id={id}
            label={label}
            InputLabelProps={inputLabelProps}
            fullWidth={fullWidth}
            type={type}
            onClick={() => (sideEffect ? sideEffect() : null)}
            onChange={handleChange} // send value to hook form
            value={field.value} // input value
            name={field.name} // send down the input name
            inputRef={field.ref} // send input ref, so we can focus on input when error appear
            disabled={disabled || readOnly}
            required={required}
            multiline={multiLine}
            onKeyDown={onKeyDown}
            rows={8}
            sx={[
                theme => ({
                    margin: `${theme.spacing(1)} 0`,
                    minWidth: 230,
                    ...sxTheme?.(theme),
                }),
                sxCustom,
            ]}
            {...rest}
            {...(isPublishContent && {
                variant: 'standard',
            })}
            InputProps={IProps}
        />
    );
}

export default TextFieldElement;
