export const languagePerCountries: Record<string, string[]> = {
    da: 'dk'.split(','),
    el: 'gr,cy'.split(','),
    sv: 'se,ax,sv'.split(','),
    it: 'it,ch,sm,va'.split(','),
    de: 'de,at,ch,li'.split(','),
    nl: 'nl,an,aw,be,sr'.split(','),
    pt: 'pt,ao,br,cv,gw,mz,st,tl'.split(','),
    es: 'es,ar,bo,cl,co,cr,cu,do,ec,gq,gt,hn,mx,ni,pa,pe,pr,pn,py,sv,uy,ve'.split(','),
    en: 'gb,ag,ai,aq,as,au,bb,bm,bs,bw,bz,ca,cc,ck,cx,dm,fj,fk,fm,gd,gg,gh,gi'.split(','),
    fr: 'fr,bf,bi,bj,bl,cd,cf,cg,ci,cm,dj,ga,gf,gn,gp,ht,mc,mf,ml,mq,nc,ne,pf,pm,re,sc,sn,td,tf,tg,wf,yt'.split(
        ',',
    ),
};

export const languageToCountry = (isoCode: string) => languagePerCountries[isoCode]?.[0] || '';
